
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    const show = () => {
      cash('#example-dropdown').dropdown('show')
    }
    const hide = () => {
      cash('#example-dropdown').dropdown('hide')
    }
    const toggle = () => {
      cash('#example-dropdown').dropdown('toggle')
    }

    return {
      show,
      hide,
      toggle
    }
  }
})
